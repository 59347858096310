<template data-app>
  <v-container>
    <v-card class="pb-2">
      <v-card-title>{{ item.title }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="5">
              <v-row class="row-d-block mb-4">
                <v-subheader>Тема <span style="color: red">*</span></v-subheader>
                <v-text-field
                    v-model="item.title"
                    type="text"
                    :rules="[v => !!v || 'Укажите название']"
                    required
                    outlined
                    style="align-items: baseline;"
                />
              </v-row>
              <v-row class="row-d-block mb-4">
                <v-subheader>Статус</v-subheader>
                <v-checkbox
                    v-model="item.status"
                    true-value="1"
                    false-value="0"
                    label="Опубликовано"
                    outlined
                    style="align-items: baseline; width: fit-content"
                />
              </v-row>
              <v-row class="row-d-block mb-4">
                <v-subheader>Дата публикации</v-subheader>
                <Date v-model="item.date"/>
              </v-row>
              <!--              <v-row class="row-d-block mb-4">
                              <v-subheader>Теги</v-subheader>
                              <v-row>
                                <v-chip color="#f4f0ed" class="mr-3 mb-2"
                                        v-for="tag in tags" :key="tag">
                                  #{{ tag }}
                                  <v-btn :ripple="false" icon class="ml-2" @click="removeTag(tag)">
                                    <v-icon size="16">mdi-close</v-icon>
                                  </v-btn>
                                </v-chip>
                              </v-row>
                              <v-row>
                                <v-text-field
                                    v-model="newTag"
                                    placeholder="Новый тег..."
                                    :error-messages="newTagError"
                                    @input="newTagError = ''"
                                    outlined
                                />
                                <v-btn @click="addTag" class="ml-2" :ripple="false">Добавить тег</v-btn>
                              </v-row>
                            </v-row>-->
              <v-row>
                <v-col class="px-0">
                  <v-text-field
                      class="mb-4"
                      v-model="item.html_title"
                      placeholder="html Заголовок"
                      outlined
                      show-details="auto"
                  />
                  <v-textarea
                      class="mb-4"
                      placeholder="html описание"
                      v-model="item.html_description"
                      rows="2"
                      outlined
                      auto-grow
                  />
                  <v-text-field
                      class="mb-4"
                      v-model="item.html_keywords"
                      placeholder="html Ключевые слова"
                      outlined
                      show-details="auto"
                  />
                  <v-row class="row-d-block">
                    <v-subheader>Краткое описание</v-subheader>
                    <v-textarea
                        v-model="item.text_min"
                        rows="2"
                        outlined
                        auto-grow
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="6">
              <div class="img-container mb-4" v-if="item.img && item.img.includes('https://')">
                <img style="object-fit: cover" :src="item.img" alt="">
              </div>
              <div v-else class="img-container empty mb-4">Нет изображения</div>
              <v-row class="justify-content-between">
                <div class="image-selector">
                  <v-file-input
                      class="file-input"
                      accept="image/*"
                      outlined
                      prepend-icon=""
                      @change="changeImage"
                  />
                  <v-btn class="mr-4 image-selector-btn">
                    <v-icon>mdi-camera-outline</v-icon>
                    Изменить изображение
                  </v-btn>
                </div>
                <div class="empty-block"></div>
                <v-btn @click="item.img = ''" class="v-btn-danger">
                  <v-icon size="18">mdi-delete-outline</v-icon>
                  Удалить
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="row-d-block">
            <v-col cols="9">
              <v-row class="pa-0 justify-space-between">
                <v-subheader>Содержание</v-subheader>
                <v-switch v-model="editorMode" true-value="html" false-value="text"
                          :label="editorMode === 'text' ? 'Текст': 'HTML'" :color="'#9BF0F5'"
                />
              </v-row>
              <div v-if="editorMode ==='text'">
                <ejs-richtexteditor
                    id="rich-text-editor"
                    :toolbarSettings="toolbarSettings"
                    :insertImageSettings="imageSettings"
                    @imageUploadSuccess="onImageUploadSuccess"
                    v-model="item.text">
                </ejs-richtexteditor>
              </div>
              <CodeEditor v-else v-model="item.text" height="500px" :wrap_code="true" :hide_header="true"
                          :languages="[['markdown', 'html']]"></CodeEditor>
              <!--              <vue-editor v-model="item.text"
                                        id="editor"
                                        :use-custom-image-handler="true"
                                        @imageAdded="handleImageAdded"
                                        @image-added="handleImageAdded"

                            />-->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="update" :loading="updateLoading">{{ item.n_id ? 'Сохранить' : 'Создать' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
// import { VueEditor } from "vue2-editor";
import Date from "../../components/Date";
import Vue from "vue";
import CodeEditor from 'simple-code-editor';

import {
  RichTextEditorPlugin,
  Toolbar,
  HtmlEditor,
  QuickToolbar,
  Table,
  Image,
  MarkdownEditor,
  Link,
} from "@syncfusion/ej2-vue-richtexteditor";

Vue.use(RichTextEditorPlugin);
export default {
  name: "Item",
  provide: {
    richtexteditor: [Toolbar, HtmlEditor, Table, QuickToolbar, Image, MarkdownEditor, Link]
  },
  components: {Date, CodeEditor},
  data() {
    return {
      toolbarSettings: {
        items: ['Undo', 'Redo',
          'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent', '|',
          'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontColor', 'BackgroundColor', 'LowerCase', 'UpperCase', '|',
          'CreateLink', 'Image', '|',
          'ClearFormat', 'CreateTable', '|',
          'FullScreen',
        ]
      },
      editorMode: 'text',
      imageSettings: {
        'path': '',
        'saveUrl': 'https://cleanbros.ru/upload'
      },
      item: {
        img: '',
        tags: []
      },
      loading: false,
      updateLoading: false,
      newTag: '',
      newTagError: '',
      tags: [],
      date_menu: false,
    }
  },
  methods: {
    get(id) {
      this.loading = true;
      this.$store.state.server.request(`blog/get/${id}`, {}, (data) => {
        this.item = data.response;
        this.loading = false;
        this.item.tags = this.item.tags.replace('#', '');
        if (this.item.tags !== '') {
          this.tags = this.item.tags.split(',') ? this.item.tags.split(',') : [this.item.tags];
        }
      }, () => {
        this.loading = false;
      });
    },
    onImageUploadSuccess(args) {
      const apiFileName = JSON.parse(args.e.currentTarget.response).response;
      args.file.name = apiFileName;
      let filename = document.querySelectorAll(".e-file-name")[0];
      filename.innerHTML = args.file.name.replace(apiFileName, '');
      filename.title = args.file.name;
    },
    removeTag(tag) {
      let removedTagIndex = this.tags.indexOf(tag);
      if (removedTagIndex > -1) {
        this.tags.splice(removedTagIndex, 1);
      }
    },
    addTag() {
      if (this.newTag === '') {
        this.newTagError = 'Введите тег';
        return;
      }
      this.tags.push(`${this.newTag}`);
      this.newTag = '';
    },
    changeImage(file) {
      if (file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.axios.post('https://cleanbros.ru/upload', formData)
            .then(data => {
              this.item.img = data.data.response;
            })
            .catch(e => {
              this.$root.notify(e, 'error')
            })
      }
    },
    update() {
      if (!this.$refs.form.validate()) return;

      const url = `blog/${this.item.n_id ? 'update/' + this.item.n_id : 'create'}`;
      const dataToRequest = {...this.item};
      if (+dataToRequest.status) {
        if (!dataToRequest.date) return this.$root.notify('Укажите дату публикации', 'error')
        if (!this.tags.length) return this.$root.notify('Укажите теги', 'error')
        if (!dataToRequest.img) return this.$root.notify('Загрузите изображение', 'error')
      }
      this.updateLoading = true;
      dataToRequest.tags = this.tags.join(',').replace('#', '');
      this.$store.state.server.request(url, dataToRequest, (data) => {
        if (dataToRequest.n_id) this.$root.notify('Статья сохранена');
        else {
          this.$root.notify('Статья успешно создана');
          this.$router.push(`/blog/${data.response.n_id}`);
        }
        this.updateLoading = false;
      }, () => {
        this.updateLoading = false;
      });
    },
    hideSFAlerts() {
      let alldivSpan = document.querySelectorAll('div span');
      alldivSpan.forEach((span) => {
        let spanText = span.innerHTML;
        if (spanText.includes('This application')) {
          let spanParent = span.parentNode;
          spanParent.style.display = 'none';
        }
      });
      let allDivs = document.querySelectorAll('div');
      allDivs.forEach((div) => {
        let divText = div.innerHTML;
        if (divText.includes('Claim your FREE account and get a key in less than a minute')) {
          let divFirstParent = div.parentNode.parentNode.parentNode;
          if (divFirstParent.tagName === 'DIV') {
            divFirstParent.style.display = 'none';
          }
        }
      })
    }
  },
  mounted() {
    this.hideSFAlerts();
    if (this.$route.params.id !== '-1') this.get(this.$route.params.id);
  }
}
</script>

<style>
@import "/node_modules/@syncfusion/ej2-base/styles/material.css";
@import "/node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "/node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "/node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "/node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "/node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "/node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>
<style lang="scss">
.img-container {
  height: 300px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  object-fit: contain;

  img {
    width: 100%;
    height: 100%;
  }

  &.empty {
    background: #f4f0ed;
  }
}

.image-selector {
  position: relative;

  .file-input, .image-selector-btn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .image-selector-btn {
    z-index: 1;
  }

  .file-input {
    opacity: 0;
    width: 212px;
    min-width: 212px;

    .v-input__slot {
      cursor: pointer;
    }
  }
}

.v-application .code_editor > .code_area > pre > code {
  position: absolute;
  width: 100%;
}
</style>
